<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="6" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="4">
              <h6 class="text-primary"> <small class="text-primary">Nama SKPD</small><br>
                <strong>{{ data.item.kode_dinas }} - {{ data.item.nama_dinas }}</strong>
              </h6>

            </b-col>
            <b-col md="3">
              <h6><small>Pagu Anggaran</small><br><strong class="text-warning" style="font-size : 20px;"><sup>Rp. </sup>{{
                Rp(data.item.pagu_anggaran) }}</strong></h6>



            </b-col>
            <b-col md="3">
              <h6 class="text-primary"><small>Total Objek pendapatan</small><br><strong class="text-primary"
                  style="font-size : 20px;"><sup>Rp.
                  </sup>{{
                    Rp(data.item.anggaran) }}</strong></h6>
              <!-- <h6><small>Realisasi Periode Lalu</small><br><strong class="text-primary"><sup>Rp. </sup>{{
                Rp(data.item.periode_lalu) }}</strong></h6>
              <h6><small>Periode Ini</small><br><strong class="text-primary"><sup>Rp. </sup>{{
                Rp(data.item.periode_ini) }}</strong>
              </h6> -->
            </b-col>
            <b-col md="2">
              <b-button variant="outline-primary" block size="sm" class="mt-1" v-b-modal.modal-1
                @click="LoadDetail(data.item)">
                <feather-icon icon="GridIcon" size='15' /> Detail
              </b-button>
              <!-- <h6><small>Total Realisasi</small><br><strong class="text-primary"><sup>Rp. </sup>{{
                Rp(data.item.total_ralisali) }}</strong></h6>
              <h6><small>Sisa Anggaran</small><br><strong class="text-primary"> <sup>Rp. </sup>{{
                Rp(data.item.sisa_anggaraan) }}</strong></h6> -->
            </b-col>

          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="6">
      Total Data {{ totalRows }}
    </b-col>
    <b-col cols="6">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  props: {
    rs_data: {},
    totalRowsa: {}
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],

    }
  },
  computed: {
    items: {
      get: function () {
        return this.rs_data;
      },
      set: function (newValue) {
        return this.rs_data = newValue;
      }
    },
    totalRows: {
      get: function () {
        return this.totalRowss;
      },
      set: function (newValue) {
        return this.totalRowss = newValue;
      }
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
  },
  emits: ['LoadDetail'],
  methods: {
    LoadDetail(val) {
      this.$emit('LoadDetail', val)
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>