<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <tabel-objek :rs_data=rs @LoadDetail="LoadDetail"></tabel-objek>
        </b-card>
      </b-col>
    </b-row>
    <detail_objek_pendapatanVue :rs_detail="rs_detail" :detailObjek="detailObjek" @EditData=EditData
      @deleteData="deleteData">
    </detail_objek_pendapatanVue>
    <edit_objek_pendapatan :rs_edit="rs_edit" @UpdateObjek="UpdateObjek"></edit_objek_pendapatan>
    <tambah_objek_pendapatan :kode_1="kode_1" :id_pagu_pendapatan="id_pagu_pendapatan" @simpanObjek="simpanObjek">
    </tambah_objek_pendapatan>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BRow, BCol, BCard
} from 'bootstrap-vue'
import TabelObjek from './component/TabelObjek.vue'
import detail_objek_pendapatanVue from './component/DetailObjekpendapatan.vue'
import edit_objek_pendapatan from './component/EditObjekpendapatan.vue'
import tambah_objek_pendapatan from './component/TambahObjekpendapatan.vue'
export default {
  components: {
    BTabs,
    BTab, BRow, BCol, BCard, tambah_objek_pendapatan,
    TabelObjek, detail_objek_pendapatanVue, edit_objek_pendapatan
  },
  props: {

  },
  data() {
    return {
      rs: [],
      rs_detail: {},
      detailObjek: {},
      thAnggaran: '',
      rs_edit: {},
      id_pagu_pendapatan: "",
      id_master_dinas: "",
      kode_1: []
    }
  },
  mixins: [Base],
  mounted() {
    this.load_data();
    this.th_anggaran();
    this.load_kode_1()
  },
  methods: {
    async simpanObjek(input) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/objek/pendapatan/insert',
        data: {
          id_kode_4: input.kode_4.id_kode_4,
          id_master_dinas: self.id_master_dinas,
          id_pagu_pendapatan: self.id_pagu_pendapatan,
          anggaran: input.anggaran

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('primary', "", response.data.message);
          self.load_data();
          self.laod_objek(self.id_pagu_pendapatan);
          self.detail_pagu();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async detail_pagu() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/objek/pendapatan/detail_pagu',
        data: {
          id_pagu_pendapatan: self.id_pagu_pendapatan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_detail = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_kode_1() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/objek/pendapatan/kode_1',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kode_1 = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async deleteData(id_objek_pendapatan) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/objek/pendapatan/delete',
        data: {
          id_objek_pendapatan: id_objek_pendapatan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.load_data();
          self.laod_objek(self.id_pagu_pendapatan);
          self.detail_pagu();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async UpdateObjek(input) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/objek/pendapatan/update',
        data: {
          anggaran: input.anggaran,
          id_objek_pendapatan: input.id_objek_pendapatan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.load_data();
          self.laod_objek(self.id_pagu_pendapatan);
          self.detail_pagu();
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    EditData(val) {
      this.rs_edit = val
    },
    LoadDetail(val) {
      this.rs_detail = val
      this.id_pagu_pendapatan = val.id_pagu_pendapatan;
      this.id_master_dinas = val.id_master_dinas;
      this.laod_objek(val.id_pagu_pendapatan);
    },
    async laod_objek(id_pagu_pendapatan) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/objek/pendapatan/laod_objek',
        data: {
          id_pagu_pendapatan: id_pagu_pendapatan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.detailObjek = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async th_anggaran() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/objek/pendapatan/th_anggaran',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.thAnggaran = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/objek/pendapatan/load_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>