<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-2" title="Edit Objek pendapatan" ok-disabled no-close-on-backdrop hide-footer size="lg">
      <span>Kode Objek pendapatan </span>
      <h5> <strong>{{ rs_edit.kode_4 }} - {{ rs_edit.uraian }}</strong> </h5>
      <b-form-group label="Anggaran Objek pendapatan Rp. " label-for="basicInput">
        <currency-input v-model="rs_edit.anggaran" class="form-control"></currency-input>
      </b-form-group>
      <hr>
      <b-button variant="primary" size="sm" @click="UpdateObjek()">
        <feather-icon icon="SaveIcon" size='15' /> Update
      </b-button>
      <b-button variant="warning" size="sm" class="ml-1" @click="close()">
        <feather-icon icon="XIcon" size='15' /> Keluar
      </b-button>

    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import CurrencyInput from './CurrencyInput.vue'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BFormGroup, BFormInput,
    CurrencyInput
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["UpdateObjek"],
  methods: {
    UpdateObjek() {
      if (confirm('Apakah anda yakin akan mengupdate ,objek pendapatan ini....?')) {
        this.$emit('UpdateObjek', this.rs_edit);
        this.close();
      }
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-2')
      this.rs_edit = {}
    }
  },
  props: {
    rs_edit: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>