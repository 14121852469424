<template>
  <div>
    <b-modal id="modal-1" title="Detail Objek Pendapatan" ok-disabled no-close-on-backdrop hide-footer size="xl">
      <b-row>
        <b-col md="6">
          <h5 class="text-primary"> <small class="text-primary">Nama SKPD</small><br>
            <strong>{{ rs_detail.kode_dinas }} - {{ rs_detail.nama_dinas }}</strong>
          </h5>
        </b-col>
        <b-col md="3">
          <h6><small>Target Pendapatan</small><br><strong class="text-warning" style="font-size : 20px;"><sup>Rp. </sup>{{
            Rp(rs_detail.pagu_anggaran) }}</strong></h6>
        </b-col>
        <b-col md="3">
          <h6 class="text-primary"><small>Total Objek Pendapatan</small><br><strong class="text-primary"
              style="font-size : 20px;"><sup>Rp.
              </sup>{{
                Rp(rs_detail.anggaran) }}</strong></h6>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col md="9">
          <h4><feather-icon icon="GridIcon" size='15' /> Rincian Objek Pendapatan</h4>
        </b-col>
        <b-col md="3"> <b-button variant="primary" block size="sm" v-b-modal.modal-5>
            <feather-icon icon="PlusCircleIcon" size='15' /> Tambah Objek Pendapatan
          </b-button></b-col>
      </b-row>
      <hr>
      <div class="mb-1">
        <b-row>
          <b-col md="2">
            <h4>Kode</h4>
          </b-col>
          <b-col md="5">
            <h4>Uraian</h4>
          </b-col>
          <b-col md="4" class="text-center">
            <h4 style="float: right">Anggaran (Rp)</h4>
          </b-col>
          <b-col md="1" class="text-center"></b-col>
        </b-row>
      </div>
      <div v-for="Objek in detailObjek" v-bind:key="Objek">
        <!-- <b-alert variant="warning" show="" style="margin-bottom: -2px;">
          <div class="alert-body" style="margin-left: -10px; margin-bottom: -3px;">
            
          </div>
        </b-alert> -->
        <b-row class="bg-h1">
          <b-col md="2" class="ml-1"> <strong>{{ Objek.kode_1 }}</strong> </b-col>
          <b-col md="5"><strong>{{ Objek.uraian }}</strong></b-col>
          <b-col md="4">
            <h4 class="text-primary"><strong style="float: right"> {{
              Rp(Objek.anggaran)
            }}</strong></h4>
          </b-col>
        </b-row>
        <div v-for="kode_2 in Objek.kode_2" v-bind:key="kode_2">
          <b-row class="bg-h2">
            <b-col md="2" class="ml-1"> <strong>{{ kode_2.kode_2 }}</strong> </b-col>
            <b-col md="5" class="ml-2"><strong>{{ kode_2.uraian }}</strong></b-col>
            <b-col md="4" class="text-center">
              <h5><strong style="margin-left: 245px;" class=""> {{
                Rp(kode_2.anggaran)
              }}</strong></h5>
            </b-col>
          </b-row>
          <div v-for="kode_3 in kode_2.kode_3" v-bind:key="kode_3">
            <b-row class="bg-h3 ">
              <b-col md="2" class="ml-1"><strong> {{ kode_3.kode_3 }}</strong> </b-col>
              <b-col md="5" class="ml-3"><strong>{{ kode_3.uraian }}</strong></b-col>
              <b-col md="4" class="text-center">
                <h5><strong style="margin-left: 165px;" class=""> {{
                  Rp(kode_3.anggaran)
                }}</strong></h5>
              </b-col>
            </b-row>
            <div v-for="kode_4 in kode_3.kode_4" v-bind:key="kode_4">
              <b-row class="hrs">
                <b-col md="2" class="ml-1"> {{ kode_4.kode_4 }} </b-col>
                <b-col md="4" class="ml-4">{{ kode_4.uraian }}</b-col>
                <b-col md="4" class="text-center">
                  <h6><span style="margin-left: 165px;" class=""> {{
                    Rp(kode_4.anggaran)
                  }}</span></h6>
                </b-col>
                <b-col md="1">
                  <b-badge variant="warning" @click="EditData(kode_4)" v-b-modal.modal-2>
                    <feather-icon icon="EditIcon" size='15' />
                  </b-badge>
                  <b-badge variant="danger" class="ml-1" @click="deleteData(kode_4.id_objek_pendapatan)">
                    <feather-icon icon="TrashIcon" size='15' />
                  </b-badge>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BCard, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal, BBadge,
    BAlert, BRow, BCol, BCard,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["EditData", "deleteData"],
  methods: {

    EditData(val) {
      this.$emit('EditData', val)
    },
    deleteData(val) {
      if (confirm('Apakah anda akan menghapus data ini...?')) {
        this.$emit('deleteData', val)
      }
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    close() {
      this.$root.$emit('bv::hide::modal-1', 'AddLevel')
    }
  },
  props: {
    rs_detail: {},
    detailObjek: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style scoped>
.hrs {
  border: 0;
  border-top: 1px solid #ebe9f1;
}

.bg-h1 {
  background: #dfd7f5;
  padding: 5px;
  color: rgb(0, 0, 0);
}

.bg-h2 {
  background: #ece7fa;
  padding-top: 5px;
  color: rgb(0, 0, 0);
}

.bg-h3 {
  background: #f1edfc;
  padding-top: 5px;
  color: rgb(0, 0, 0);
}
</style>